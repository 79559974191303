.modalAddCriteria {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperAddCriteria {
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 2%;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inputAddCriteria {
  width: 300px !important;
  margin: 10px !important;
}

.operator {
  width: 100px !important;
  margin: 10px !important;
}

.buttonMore {
  background-color: #a00095 !important;
  color: #fff !important;
  font-weight: bold !important;
  height: 40px !important;
  align-self: center !important;
}

.buttonMore:hover {
  background: rgba(122, 33, 132, 255) !important;
  box-shadow: 0px 5px 5px rgb(138, 20, 140) !important;
  transform: translateY(+1px) !important;
}

.criteriaConditionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  height: 230px;
  padding-right: 10px;
}

.criteriaConditionsContainer::-webkit-scrollbar {
  width: 3.1px;
  background-color: #d9dada;
  padding-right: 10px !important;
}

/* Handle */
.criteriaConditionsContainer::-webkit-scrollbar-thumb {
  width: 5px;
  background: #575756;
  margin-right: 10px !important;
  border-radius: 5px;
}
