.modalAddValidation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperAddValidation {
  background-color: #fff;
  border-radius: 14px;
  padding: 20px 30px;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
}

.inputContainer {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
}

.formControlAddValidation {
  width: 50% !important;
  min-width: 15vw !important;
  margin: 0 8px !important;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.buttonAcceptValidation {
  margin: 1% !important;
  background-color: #a00095 !important;
  color: #fff !important;
  font-weight: bold !important;
  width: 25% !important;
  border-radius: 4px !important;
  margin-top: 2vh !important;
}

.buttonAcceptValidation:hover {
  background: rgba(122, 33, 132, 255) !important;
  box-shadow: 0px 5px 5px rgb(138, 20, 140) !important;
  transform: translateY(+1px) !important;
}

.buttonCancelValidation {
  margin: 1% !important;
  background-color: #fff !important;
  color: #a00095 !important;
  font-weight: bold !important;
  width: 25% !important;
  border: 1px solid #a00095 !important;
  border-radius: 4px !important;
  margin-top: 2vh !important;
}

.buttonCancelValidation:hover {
  box-shadow: 0px 5px 5px rgb(138, 20, 140) !important;
  transform: translateY(+1px) !important;
}
