.subtitleViewIndex {
  width: 10%;
  margin: 0 0px 0 25px;
}

.subtitleViewDescription {
  width: 50%;
}

.viewProcessRow {
  display: flex;
  flex-direction: row;
  place-content: center;
}

.viewProcessGrid {
  display: flex;
  flex-direction: column;
  place-content: center;
}
