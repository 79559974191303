.contentTableValidations{
  overflow-y: scroll;
  height: 486px;
  width: 100%;
}

.contentTableValidations::-webkit-scrollbar {
  width: 3px;
  background-color:#D9DADA ;

}


.contentTableValidations::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #575756;
  border-radius: 10px;
}


.rowsValidation {
  display: flex;
  flex-direction: row;
  place-content: space-evenly;
  align-items: center;
  width: 90%;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid #c9c9c9;
}

.rowsValidation:hover {
  background-color: #f7f7f7;
}









