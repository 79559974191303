.buttonBackground {
  height: 50%;
}

.buttonDeleteCustomColumn {
  border-radius: 15px;
}

.buttonDeleteCustomColumn:hover {
  background-color: #fff;
}

.headerBoxRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 6%;
  justify-content: space-between;
  margin-left: 10px;
}

.headerBoxFlex {
  display: flex;
}

.chipContainerRow {
  display: flex;
  flex-direction: row;
  place-content: center;
  align-items: center;
}

.chipContainerGrid {
  display: flex;
  place-content: center;
}

.addBoxOne {
  color: #ff5c00;
}

.addBoxExcluding {
  color: #ff0082;
}

.addBoxCondition {
  color: #a00095;
}

.addBoxFill {
  color: #2d00a0;
}

.chip {
  margin: 1% !important;
  color: #fff !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-family: Noto Sans, sans-serif !important;
  width: 220px !important;
  border-radius: 12px !important;
}

.linkEntradas {
  background-color: #ff5c00 !important;
}

.linkExcluding {
  background-color: #c30091 !important;
}

.lite {
  width: 220px !important;
}

.liteSpecial {
  width: 220px !important;
  margin-right: 10px !important;
}

.linkConditions {
  background-color: #ff0082 !important;
}

.specialOperations {
  background-color: #2d00a0 !important;
}

.iconHeaderBox:hover {
  color: #a00095 !important;
}

