.modalAddHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperModalAddHeader {
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  width: 31%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titleModalAddHeader {
  color: #ff0081;
  font-family: "SancoaleSoftened-Medium", sans-serif;
  font-size: 30px;
  align-self: flex-start;
  margin-bottom: 8px;
}
