.commonChannels {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.tableChannelContainer {
  width: 35%;
  margin: 0;
  background-color: #fff;
}

.tableTemplateContainer {
  width: 64%;
  margin: 0;
  margin-left: 1%;
  background-color: #fff;
  border-left: 1px solid #575756;
  margin-bottom: 50px;
}

.tableTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.listItemTemplate {
  padding: 0 !important;
}
.overTableTemplates {
  width: 100%;
  height: 406px;
  overflow-y: scroll;
}

.overTableTemplates::-webkit-scrollbar {
  width: 3px;
  background-color: #d9dada;
  padding: 4px !important;
  box-sizing: border-box;
}

/* Handle */
.overTableTemplates::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #780095;
  border-radius: 20px;
}

.rowTemplate {
  width: 90% !important;
  padding: 0 !important;
  justify-content: "space-evenly" !important;
  border: solid 1px #780095 !important;
  border-top: none !important;
}

.rowTemplate:hover {
  background-color: #f7f7f7 !important;
}

.templateGrid {
  display: flex;
  justify-content: center;
  border-right: 1px solid #780095;
  height: 80px;
  color: #474746;
  font-family: Noto Sans, sans-serif;
  padding: 10px;
}

.templateName {
  align-items: center;
  width: 12%;
  min-width: 12%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.templateSelected {
  align-items: center;
  width: 5%;
  min-width: 5%;
}

.templateName::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d9dada;
}

/* Handle */
.templateName::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: #ff0081;
  border-radius: 20px;
}

.textTemplateName {
  text-align: center;
  padding: 5px;
  width: 100%;
  overflow-wrap: break-word !important;
}

.templateSelectedS {
  text-align: center;
  padding: 5px;
  width: 100%;
}

.templateState {
  align-items: center;
  width: 10%;
}

.templateDoubleWay {
  align-items: center;
  width: 15%;
}

.templatePreview {
  align-items: flex-start;
  text-align: left;
  width: 25%;
  font-size: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.templatePreview::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d9dada;
}

/* Handle */
.templatePreview::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: #ff0081;
  border-radius: 20px;
}
.templatePhoneCode {
  align-items: center;
  width: 15%;
}

.templateActions {
  align-items: center;
  width: 15%;
  gap: 20px;
}

.actionIcon {
  align-self: flex-end;
  width: 16px;
  transition: 0.3s ease-out;
}

.actionIcon:hover {
  width: 20px;
}

.boxChannels {
  width: 100%;
}

.containerChannels {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.checkboxChannels {
  width: 15%;
}

.tableHeaderChannels {
  display: flex;
  flex-direction: row;
  gap: 4%;
  align-items: center;
  height: 4rem;
  width: 100%;
  color: #575756;
}

.tableHeaderTemplates {
  display: flex;
  flex-direction: row;
  gap: 5%;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 100%;
  color: #575756;
}

.first_rowTemplates {
  display: flex;
  flex-direction: row;
  place-content: space-around;
  align-items: center;
  color: #fff;
  width: 90%;
  margin-top: 29px;
  background-color: #780095;
  height: 50px;
  margin-right: 2px;
  font-weight: 300 !important;
}
.titleChannels {
  font-family: "SancoaleSoftened-Medium", sans-serif;
  font-size: 25px;
}

.titleTemplatesTable {
  font-family: "SancoaleSoftened-Regular", sans-serif;
  text-align: center;
  font-size: 20px;
  color: #fff;
}

.selectedTitle {
  padding: 10px;
  width: 5%;
}

.nametitle {
  width: 15%;
}

.stateTitle {
  width: 15%;
}

.doublewaytitle {
  width: 15%;
}

.previewTitle {
  width: 23%;
}
.phoneCodeTitle {
  width: 20%;
}
.actionsTitle {
  width: 15%;
}

.buttonInteracciones {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  background-color: #780095;
  color: #fff;
  border-style: none;
  padding: 5px 15px;
  border-radius: 8px;
  font-size: 19px;
  font-family: "SancoaleSoftened-Regular", sans-serif;
  height: 35px;
}
.buttonInteracciones:hover {
  background-color: #ff0081 !important;
}
.buttonInteracciones:disabled {
  background-color: #575756 !important;
}
.nameChannel {
  color: #575756;
  font-family: "SancoaleSoftened-Regular", sans-serif;
  width: 30%;
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.buttonsChannelContainer {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.buttonChannel {
  background-color: #fff;
  border: 1px solid #780095;
  color: #780095;
  border-radius: 8px;
  width: 110px;
  height: 35px;
}

.buttonChannel:hover {
  background-color: #780095;
  border-color: #780095;
  color: #fff;
}

.ButtonTemplates {
  background-color: #780095;
  border-style: none;
  color: #fff;
  border-radius: 8px;
  width: 80px;
  height: 35px;
}

.ButtonTemplates:hover {
  background-color: #ff0081;
}

.listItem {
  margin-bottom: 2%;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  border-radius: 25px;
}

.listItem:hover {
  background-color: #f7f7f7;
}

.noChannels {
  margin-top: 50px;
  color: #474746;
}
