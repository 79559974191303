.modalAddCondition {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperAddCondition {
  background-color: #fff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 14px;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  width: 720px;
  display: flex;
  height: 600px;
  flex-direction: column;
  align-items: center;
}

.inputCondition {
  width: 300px;
  margin: 10px !important;
}

.inputDays {
  width: 150px !important;
  margin: 10px !important;
}
.conditionsContainer {
  width: 100%;
  overflow-y: scroll;
  overflow-x: none;
}

.conditionsContainer::-webkit-scrollbar {
  width: 3px;
  background-color: #d9dada;
}

.conditionsContainer::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #575756;
  border-radius: 10px;
}

.subtitleConditionElse {
  color: #575756;
  font-size: 14px;
  font-family: Noto Sans, sans-serif;
  padding-left: 2%;
  margin: 12px 0;
}

.checkBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2%;
}

.radioGroup {
  justify-content: space-between !important;
}

.centerSubtitle {
  margin-top: 10px;
}
