.chartsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  margin-top: 30px;
  height: 500px;
  width: 500px;
}

.titleCharts {
  color: #780095;
  font-family: "SancoaleSoftened-Regular", sans-serif;
  font-size: 25px;
  font-weight: 900;
}

.chartsDescription {
  color: #575756;
}

.chartBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.chart {
  margin-top: -55px;
  width: 1000px !important;
  height: 600px !important;
}

