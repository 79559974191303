.formContainerScript {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.inputFile {
  width: 90%;
  align-self: center;
}
.uploadScriptButton {
  height: 54px;
  width: 150px;
  align-self: center;
  background-color: #fff !important;
  color: #a00095 !important;
  border-radius: 4px !important;
  border: 1px solid #a00095 !important;
  font-size: 16px;
  font-weight: normal !important;
  font-style: normal !important;
  font-family: Noto Sans, sans-serif !important;
}

.uploadScriptButton:hover {
  color: #fff !important;
  background: rgba(122, 33, 132, 255) !important;
  transform: translateY(+1px) !important;
}
