body {
  height: 100vh;
}
.main_bar {
  display: flex;
  flex-direction: row;
  height: 11.94vh;
  width: 90%;
  margin: 0 auto;
  background: #f6f6f6;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029 !important;
  margin-bottom: 10px;
  justify-content: space-between;
  border-radius: 10px;
}

.tpLogoMainBar {
  width: 100%;
  height: 100%;
}

.item1 {
  display: flex;
  flex-direction: row;
  width: 20%;
  justify-self: center;
  margin-left: 2%;
}

.itemCenter {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 65%;
}

.clientsTitle {
  font-family: "SancoaleSoftened-Medium", sans-serif;
}

.clientNameProgressBar {
  color: #474746;
  font-family: "SancoaleSoftened-Regular", sans-serif;
  text-align: center;
  align-self: center;
  font-size: 30px;
}

.userIcon {
  margin-left: 15px;
  width: 35px;
  margin-top: 5px;
}

.item2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 20%;
  align-items: center;
  padding-right: 1.4%;
}

.item2 span {
  min-width: 300px;
  text-align: end;
  color: #575756;
  font-size: 23px;
}

.mainBarButtons {
  background-color: transparent;
  color: #a00095;
  border: none;
  border-radius: 4px;
  margin-right: 8px;
  transition: 0.2s;
}

.mainBarButtons:hover {
  transform: translateY(+1px);
}

.userNameLabel {
  font-weight: bold;
  color: #575756;
}

.logOutIcon {
  width: 34px;
  margin-top: 7px;
}
