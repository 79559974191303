.TableInputStrategy {
  overflow-y: scroll;
  height: 500px;
  width: 80%;
  margin-top: 30px;
}

.TableInputStrategy::-webkit-scrollbar {
  width: 3px;
  background-color: #d9dada;
}

.TableInputStrategy::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #575756;
  border-radius: 10px;
}
