.paperInteractions {
  width: 80%;
  height: 90vh;
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.paperInteractions .buttonClose {
  align-self: flex-end;
  width: 14px;
  transition: 0.3s ease-out;
}

.paperInteractions .buttonClose:hover {
  transform: rotate(90deg);
  opacity: 0.5;
}

.paperInteractions .buttonNuevaInteraccion {
  align-self: flex-end;
}

.buttonNuevaInteraccion {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  background-color: #780095;
  color: #fff;
  border-style: none;
  padding: 5px 15px;
  border-radius: 8px;
  font-size: 19px;
  font-family: "SancoaleSoftened-Regular", sans-serif;
  height: 35px;
}

.containerInteractions {
  width: 100%;
  height: 600px;
  overflow-y: scroll;
  margin-top: 20px;
}

.containerInteractions::-webkit-scrollbar {
  width: 0.2em;
  background: #a0a09f;
  border-radius: 35px;
  border: 1px solid #fff;
}

/* Handle */
.containerInteractions::-webkit-scrollbar-thumb {
  background: rgb(255, 0, 130);
  border-radius: 35px;
}

.interactionFlow {
  display: none;
}

.deployed {
  display: flex;
  gap: 10px;
  align-self: flex-start;
  padding-left: 2.5%;
  margin-bottom: 20px;
}

.interactionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.interactionRow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 95%;
  gap: 10px;
  margin-bottom: 20px;
}

.buttonsIcon {
  width: 16px;
  transition: 0.3s ease-out;
}

.buttonsIcon:hover {
  width: 20px;
}

.deployArrowIcon {
  width: 16px;
  transform: rotate(90deg);
  align-self: center;
}

.deployedIcon {
  transform: rotate(270deg);
}

.interactionName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  padding: 0 15px;
  font-family: "SancoaleSoftened-Regular", sans-serif;
  font-size: 20px;
  color: #575756;
}

.interactionName:hover {
  background-color: #f6f6f6;
}

.bodyCardInteraction {
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: 14px;
  gap: 5px;
  padding: 20px 15px 20px 15px;
  height: 240px;
  width: 200px;
  margin-top: 10px;
}

.bodyCardInteraction label {
  font-family: "SancoaleSoftened-Medium", sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
}

.selectInteractions {
  border: 1px solid #707070;
  height: 30px;
  border-radius: 10px;
  padding: 0 10px;
  font-family: Noto Sans, sans-serif;
  width: 90%;
  margin-bottom: 5px;
}

.addCard {
  background: none;
  border: none;
  width: 100px;
}

.titleCard {
  color: #ff0081;
  margin-left: 15px;
  font-family: "SancoaleSoftened-Medium", sans-serif;
  font-size: 25px;
}

.titleModalInteractions {
  color: #ff0081;
  font-family: "SancoaleSoftened-Medium", sans-serif;
  font-size: 27px;
  align-self: flex-start;
  margin-bottom: 17px;
}

.inputModalInteractions {
  box-sizing: border-box;
  height: 30px;
  border: 0.7px solid #575756;
  border-radius: 10px;
  width: 90%;
  margin-bottom: 12px;
  padding: 2px 15px;
}

.inputModalInteractions:focus {
  border: none;
  outline: 0.5px solid #ff0081 !important;
}
