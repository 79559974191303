.modalCsv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperCsv {
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.titleModalCsv {
  color: #ff0081;
  font-family: "SancoaleSoftened-Medium", sans-serif;
  font-size: 30px;
  align-self: flex-start;
  margin-bottom: 5px;
}
.subtitleModal {
  color: #575756;
  font-size: 14px;
  font-family: Noto Sans, sans-serif;
  margin-bottom: 20px;
}

.formCsv {
  width: 50%;
  align-self: center;
}

.labelModalCsv {
  font-family: Noto Sans, sans-serif;
  font-size: 16.5px;
  color: #575756;
  text-align: left;
  align-self: flex-start;
  font-weight: 500;
}
.inputModalCsv {
  box-sizing: border-box;
  height: 40px;
  border: 0.7px solid #575756;
  border-radius: 10px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 25px;
  padding: 2px 15px;
}

.tableCsv {
  width: 100% !important;
}

.buttonAcceptFile {
  margin: 1% !important;
  background-color: #a00095 !important;
  color: #fff !important;
  font-weight: bold !important;
  width: 20% !important;
  border-radius: 4px !important;
  margin-top: 2vh !important;
}

.buttonAcceptFile:hover {
  background: rgba(122, 33, 132, 255) !important;
  box-shadow: 0px 5px 5px rgb(138, 20, 140) !important;
  transform: translateY(+1px) !important;
}

.buttonCancelFile {
  margin: 1% !important;
  background-color: #fff !important;
  color: #a00095 !important;
  font-weight: bold !important;
  width: 20% !important;
  border: 1px solid #a00095 !important;
  border-radius: 4px !important;
  margin-top: 2vh !important;
}

.buttonCancelFile:hover {
  box-shadow: 0px 5px 5px rgb(138, 20, 140) !important;
  transform: translateY(+1px) !important;
}


.closeIcon {
  align-self: flex-end;
  width: 14px;
  transition: 0.3s ease-out;
}

.closeIcon:hover {
    
    transform: rotate(90deg);
    opacity: 0.5;
  }