.modalUploadFiles {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperModalUploadFiles {
  background-color: #fff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 14px;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  width: 850px;
  display: flex;
  height: 600px;
  flex-direction: column;
  align-items: center;
}

.formControlUploadFiles {
  margin: 8px !important;
  width: 400px !important;
  min-width: 15vw !important;
}

.contenedor_modal {
  overflow-y: scroll;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
  border: solid 1px #e5e5e5;
}
/* width */
.contenedor_modal::-webkit-scrollbar {
  width: 0.2em;
}

/* Handle */
.contenedor_modal::-webkit-scrollbar-thumb {
  background: transparent;
}

.nameFile {
  width: 200px;
  margin: 10px !important;
}

.containerUpload {
  width: 90% !important;
  margin: 2% !important;
  border-radius: 25px !important;
}

.containerUpload:hover {
  background-color: #fff !important;
}

.buttonProcess {
  margin: 1% !important;
  background-color: #a00095 !important;
  color: #fff !important;
  font-weight: bold !important;
  width: 25% !important;
  border-radius: 4px !important;
}

.buttonProcess:hover {
  background: rgba(122, 33, 132, 255) !important;
  box-shadow: 0px 5px 5px rgb(138, 20, 140) !important;
  transform: translateY(+1px) !important;
}

.buttonCancelProcess {
  margin: 1% !important;
  background-color: #fff !important;
  color: #a00095 !important;
  font-weight: bold !important;
  width: 25% !important;
  border: 1px solid #a00095 !important;
  border-radius: 4px !important;
}

.buttonCancelProcess:hover {
  box-shadow: 0px 5px 5px rgb(138, 20, 140) !important;
  transform: translateY(+1px) !important;
}
