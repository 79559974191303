.modelContainerTable {
  display: flex;
  flex-direction: column;
  height: 50vh;
  overflow-y: scroll;
  width: 90%;
  padding: 10px 2%;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  margin-bottom: 50px;
  background-color: #f7f7f7;
}

.modelContainerTable::-webkit-scrollbar {
  width: 3.1px;
  background-color: #d9dada;
  padding-right: 10px !important;
}

/* Handle */
.modelContainerTable::-webkit-scrollbar-thumb {
  width: 5px;
  background: #575756;
  margin-right: 10px !important;
  border-radius: 5px;
}

.modelContainerItem {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  border: solid 1px #cdcdcd;
  margin: 0.5% 0;
  background-color: #fff;
  padding: 0 20px;
  font-family: "Noto Sans", sans-serif !important;
}

.modelContainerItem:hover {
  background-color: #d4d1d1;
}

.buttonuploadVersion:hover {
  color: #a00095 !important;
  background-color: #d4d1d1 !important;
}

.iconbuttonuploadVersion:hover {
  color: #a00095 !important;
}
