.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperCreateCollection {
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  min-width: 31%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.paperCreateCollection img {
  align-self: flex-end;
  width: 14px;
  transition: 0.3s ease-out;
}

.paperCreateCollection img:hover {
  transform: rotate(90deg);
  opacity: 0.5;
}

.titleModal {
  color: #ff0081;
  font-family: "SancoaleSoftened-Medium", sans-serif;
  font-size: 30px;
  align-self: flex-start;
  margin-bottom: 17px;
}

.labelModal {
  font-family: Noto Sans, sans-serif;
  font-size: 16.5px;
  color: #575756;
  text-align: left;
  align-self: flex-start;
  font-weight: 500;
  margin-bottom: 5px;
}

.descriptionInputModal {
  color: #a0a09f;
  font-size: 12px;
  margin-bottom: 7px;
  align-self: flex-start;
}

.formControlModal {
  display: flex !important;
  align-items: center;
  width: 99.5% !important;
  margin-top: 7px !important;
  margin-bottom: 12px !important;
}

.inputModal {
  box-sizing: border-box;
  height: 40px;
  border: 0.7px solid #575756;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 12px;
  padding: 2px 15px;
}

.inputModal:focus {
  border: none;
  outline: 0.5px solid #ff0081 !important;
}

.selectModel {
  width: 100% !important;
}

.buttonModal {
  margin: 1% !important;
  background-color: #ff0081 !important;
  color: #fff !important;
  min-width: 80px !important;
  height: 40px;
  font-size: 19px;
  border: none;
  border-radius: 7px !important;
  margin-top: 20px !important;
  font-family: "SancoaleSoftened-Regular", sans-serif !important;
  align-self: flex-end;
  padding: 0 15px;
}
.buttonModal:hover {
  background-color: #a00095 !important;
}

.modeloAnalitico {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.infotooltip {
  border: 1px solid #575756;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  padding: 5px;
  margin-bottom: 10px;
  font-size: 10px;
  color: #575756;
}
