.buttonAddCriteria {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 1%;
  background-color: #780095;
  color: #fff;
  width: 150px;
  height: 35px;
  font-size: 15px;
  border: none;
  border-radius: 7px;
  margin-top: 40px;
  font-family: "SancoaleSoftened-Regular", sans-serif;
  align-self: flex-start;
  transition: 0.2s ease-out;
}

.buttonAddCriteria:hover {
  background-color: #ff0081;
}

.subtitleTableStrategy {
  font-family: "SancoaleSoftened-Regular", sans-serif;
  font-size: 22px;
  width: 200px;
}

.chipsCriteria {
  width: 200px;
  padding-right: 20px !important;
  padding-left: 20px !important;
  color: #fff !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-family: Noto Sans, sans-serif !important;
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
}

.chipEntradasCriteria {
  background-color: #c30091 !important;
}

.chipCanal {
  background-color: #ff0081 !important;
}

.chipConditionCriteria {
  background-color: #ff5c00 !important;
}

.buttonCalculationContainer {
  display: flex;
  justify-content: flex-start;
  padding-left: 4%;
}

.contentTableCalculations {
  overflow-y: scroll;
  height: 150px;
  width: 100%;
}

.contentTableCalculations::-webkit-scrollbar {
  width: 3px;
  background-color: #d9dada;
}

.contentTableCalculations::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #575756;
  border-radius: 10px;
}
