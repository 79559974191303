.versionContainerTable {
  display: flex;
  flex-direction: column;
  height: 35vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 90%;
  padding: 10px 2%;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  margin-bottom: 5px;
  background-color: #f7f7f7;
  border: 0.5px solid #d9dada;
}

.versionContainerTable::-webkit-scrollbar {
  width: 3.1px;
  padding: 10px;
  background-color: #d9dada;
  padding-right: 10px !important;
  margin-right: 10px !important;
}

/* Handle */
.versionContainerTable::-webkit-scrollbar-thumb {
  width: 5px;
  background: #575756;
  margin-right: 10px !important;
  border-radius: 5px;
}

.versionContainerItem {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  border: solid 1px #cdcdcd;
  margin: 0.5% 0;
  background-color: #fff;
  padding: 10px 20px;
  font-family: "Noto Sans", sans-serif !important;
}

.versionContainerItem:hover {
  background-color: #d4d1d1;
}

.formcontrolVersion {
  width: 93% !important;
  margin: 10px 0 !important;
  align-self: center;
}

.buttonVersionMargin {
  margin-top: 20px;
}
