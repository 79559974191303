.div-create {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2%;
  height: 80vh;
  border-radius: 15px;
  box-shadow: 10px 5px 5px 5px rgb(229 231 233);
  width: 60%;
}

.option_post {
  margin: 2%;
  height: 55vh;
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 45vw;
  text-align: center;
}

.option_delete {
  margin: 2%;
  height: 55vh;
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 80%;
  text-align: center;
  overflow-y: scroll;
}
.view-collection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 80vh;
  margin-top: 1%;
}

.boton-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* width */
.option_delete::-webkit-scrollbar {
  width: 0.2em;
}

/* Handle */
.option_delete::-webkit-scrollbar-thumb {
  background: rgb(255, 0, 130);
  border-radius: 35px;
}

.option_title {
  font-family: "Caveat", cursive;
  font-size: 3vw;
  color: rgb(120, 0, 150);
  margin: 1%;
}

.option_subtitle {
  font-family: "Caveat", cursive;
  font-size: 4vh;
  color: rgb(120, 0, 150);
}

.option_title2 {
  font-size: 3vh;
  font-weight: bold;
  color: rgb(120, 0, 150);
  margin: 1%;
}

.db_option {
  display: flex;
  flex-direction: column;
  place-items: center;
}

.db_option_row {
  display: flex;
  flex-direction: row;
}

.subtitle_process {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.columns {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1%;
}

/* width */
.columns::-webkit-scrollbar {
  width: 0.2em;
}

/* Handle */
.columns::-webkit-scrollbar-thumb {
  background: rgb(255, 0, 130);
  border-radius: 35px;
}

.rows {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.welcome_title {
  color: rgb(120, 40, 108);
  font-family: "Caveat", cursive;
  font-size: 3vw;
}

.welcome_subtitle {
  font-family: "Caveat", cursive;
  font-size: 2vw;
}

.view-collection2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 90vh;
}
