@font-face {
  font-family: "SancoaleSoftened-Medium";
  src: url(../../assets/fonts/SancoaleSoftenedW01-Medium.ttf),
    url(../../assets/fonts/SancoaleSoftenedW01-Medium.eot),
    url(../../assets/fonts/SancoaleSoftenedW01-Medium.otf);
}

@font-face {
  font-family: "SancoaleSoftened-Regular";
  src: url(../../assets/fonts/SancoaleSoftenedW01-Regular.ttf),
    url(../../assets/fonts/SancoaleSoftenedW01-Regular.eot),
    url(../../assets/fonts/SancoaleSoftenedW01-Regular.otf);
}

body {
  background: #fff !important;
}

.container {
  background: #fff !important;
  background-color: #fff !important;
  padding-top: 3%;
}
.tableContainer {
  width: 100%;
  background-color: #fff;
  display: flex;
  place-content: center;
  flex-direction: row;
}

.tableHome {
  background-color: #ffffff;
  margin-bottom: 2%;
  width: 90%;
}

.tableBar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 69px;
}

.tableControls {
  display: flex;
  height: 70%;
  align-items: center;
  width: 60%;
  justify-content: flex-end;
  gap: 10px;
}

.buttonCreateProcess {
  background: #ff0081 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 35px;
  width: 100px;
  color: #fff !important;
  border-radius: 8px !important;
  border: none !important;
  font-size: 20px;
  font-weight: normal !important;
  font-style: normal !important;
  font-family: "SancoaleSoftened-Regular", sans-serif !important;
  margin-right: 10px !important;
  transition: transform 0.2s ease-in;
}

.buttonCreateProcess:hover {
  background: #780095 !important;
}

.selectCliente {
  width: 210px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.tabs {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
}

.homeTabs {
  background-color: #fff;
  height: 70%;
  width: 32%;
  border-radius: 18px 18px 0px 0px;
  margin-right: 10px;
  border: 1px solid #707070;
  border-bottom: none;
  color: #707070;
  font-family: "SancoaleSoftened-Medium", sans-serif;
  font-size: 25px;
  transition: 0.2s ease-out;
}
.homeTabs:hover {
  border: 1px solid #ff0081;
  border-bottom: none;
}

.selected {
  color: #fff;
  background-color: #ff0081;
  border: 1px solid #ff0081;
  border-bottom: none;
}

.project-list {
  padding: 15px;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: none;
  height: 61vh;
  width: 100%;
  border: 0.5px solid #ff0081;
}
.elementsContainer {
  height: 58vh;
  padding: 0 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* width */
.elementsContainer::-webkit-scrollbar {
  width: 3.1px;
  background-color: #d9dada;
  padding-right: 10px !important;
}

/* Handle */
.elementsContainer::-webkit-scrollbar-thumb {
  width: 5px;
  background: #575756;
  margin-right: 10px !important;
  border-radius: 5px;
}

.MuiTypography-body1 {
  font-weight: "normal" !important;
  font-style: "normal" !important;
  font-family: "Noto Sans", sans-serif !important;
}

/* ---New Css---*/

.itemHome {
  width: 100%;
  margin: 0 10px !important;
  height: 20px;
  padding: 5px !important;
}

.listItemHome {
  margin-bottom: 5px !important;
  width: 100%;
  height: 22px;
  border: solid 1px #cdcdcd;
  border-radius: 10px;
}

.listItemHome:hover {
  background-color: #f7f7f7;
}

.iconsHome {
  font-size: 20px !important;
}

.iconsHome:hover {
  color: #a00095;
}

.icons {
  width: 15px;
}

.secondaryItem {
  display: flex;
  align-items: center;
}

.buttonsHome {
  border-radius: 15px;
}

.buttonsHome:hover {
  background-color: #fff;
}
