.containerVariables {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 12px;
}

.buttonVariables {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  background-color: #ff0081;
  color: #fff;
  height: 30px;
  font-size: 12px;
  text-transform: capitalize;
  padding: 12px;
  border: none;
  border-radius: 7px;
  font-family: Noto Sans, sans-serif;
}

.buttonVariables:hover {
  background-color: #a00095;
}

.checkTemplates {
  display: flex;
  flex-direction: row;
}

.paperModalTemplate {
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.paperModalTemplate img {
  align-self: flex-end;
  width: 14px;
  transition: 0.3s ease-out;
}

.paperModalTemplate img:hover {
  transform: rotate(90deg);
  opacity: 0.5;
}

.doubleWay {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  box-sizing: border-box;
}

.inputMessage {
  box-sizing: border-box;
  height: 100px;
  border: 0.7px solid #575756;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 12px;
  padding: 10px;
}

.inputMessage:focus {
  border: none;
  outline: 0.5px solid #ff0081 !important;
}

.marginLeft30 {
  margin-left: 30px;
}

.columnsModal {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.InputPhone {
  margin: 5px 0 12px 0 !important;
}

.react-tel-input .form-control {
  width: 120px !important;
  height: 40px !important;
  border: 0.7px solid #575756 !important;
  border-radius: 10px !important;
}
