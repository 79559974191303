.chipAditionalInfo {
  margin: 1% !important;
  color: #fff !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-family: Noto Sans, sans-serif !important;
  width: 270px !important;
  background-color: #ff5c00 !important;
  border-radius: 12px !important;
}

.chipValidation {
  margin: 1% !important;
  color: #fff !important;
  font-weight: normal !important;
  font-style: normal !important;
  width: 270px !important;
  font-family: Noto Sans, sans-serif !important;
  background-color: #c30091 !important;
  border-radius: 12px !important;
}

.formControlRow {
  width: 30% !important;
  min-width: 15vw !important;
  margin: 20px 25px !important;
}

.addIcon {
  color: #a00095;
}

.columnValidationChip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rowsValidationChip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.buttonAddInfo {
  display: flex;
  justify-content: flex-end;
}
