.modalconfirmConvertion {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperConfirmConvertion {
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonNoModal {
  margin: 1% !important;
  background-color: #fff !important;
  color: #ff0081 !important;
  border: 1px solid #ff0081;
  width: 80px !important;
  height: 40px;
  font-size: 19px;
  border-radius: 7px !important;
  margin-top: 20px !important;
  font-family: "SancoaleSoftened-Regular", sans-serif !important;
  align-self: flex-end;
}
