.modalResultProcess {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperResultProcess {
  background-color: #fff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 14px;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 2%;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonFinal {
  margin: 1% !important;
  background-color: #fff !important;
  color: #a00095 !important;
  font-weight: bold !important;
  width: 100% !important;
  margin-top: 40px !important;
  border: 1px solid #a00095 !important;
  border-radius: 4px !important;
}

.buttonFinal:hover {
  box-shadow: 0px 5px 5px rgb(138, 20, 140) !important;
  transform: translateY(+1px) !important;
}

.buttonDownload {
  margin: 1% !important;
  background-color: #a00095 !important;
  color: #fff !important;
  font-weight: bold !important;
  width: 35% !important;
  margin-top: 50px !important;
  border-radius: 4px !important;
}

.buttonDownload:hover {
  background: rgba(122, 33, 132, 255) !important;
  box-shadow: 0px 5px 5px rgb(138, 20, 140) !important;
  transform: translateY(+1px) !important;
}
