.inputConfiguration {
  width: 400px;
  margin: 5px;
}

.dynamicInputs {
  width: 400px;
  margin: 5px;
  margin-bottom: 15px !important;
}

.buttonsContainerModal {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.containerConfiguration {
  display: flex;
  width: 100%;
  justify-content: center;
}

.containerDynamic {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.margingBottom35 {
  margin-bottom: 35px;
}
