.container {
  width: 100%;
  padding-top: 3%;
  height: 100vh;
}

.buttonAddFile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #780095;
  height: 34px;
  width: 152px;
  color: #fff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 19px;
  min-width: 64px;
  font-style: normal;
  font-family: "SancoaleSoftened-Regular", sans-serif;
  border: none;
  transition: 0.2s ease-out;
  margin-left: 60px;
}

.buttonAddFile:hover {
  background-color: #ff0081;
}

.buttonAddFileDisabled {
  background-color: #a0a09f;
}

.buttonAddFileDisabled:hover {
  background-color: #a0a09f;
}

.buttonAddFile img {
  width: 16px;
}

.disabled {
  border: 1px solid #575756 !important;
  color: #575756 !important ;
}

.buttonDeleteFile {
  border-radius: 4px !important;
  margin: 50% !important;
  border: none;
}

.buttonDeleteFileUploaded {
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 40px;
  height: 40px;
  background: none;
}

.buttonDeleteFileUploaded:hover {
  background-color: #e0e0e0;
}

.deleteFileIcon {
  width: 18px !important;
  transition: 0.2s ease-out;
  cursor: pointer;
}

.filesList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileName {
  margin-right: 50px;
  font-family: Noto Sans, sans-serif;
  color: #575756;
}

.progressBar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  box-sizing: border-box;
  height: 190px;
  width: 88%;
  margin: auto;
  padding: 0px 30px 20px 25px;
  border-bottom: 1px solid #780095;
}

.progressIcons img {
  width: 60px;
}

.resultIcon {
  width: 40px !important;
}

.columnsTable {
  width: 90%;
}

.columnsTable table {
  width: 100%;
  margin: 10px;
  border-radius: 20px;
  border-collapse: collapse;
}

.columnsTable table td {
  text-align: center;
  border: 1px solid #575756;
  font-family: Noto Sans, sans-serif;
  font-size: 14px;
}

.tableHeader {
  background-color: #780095;
  color: #fff;
}

.tableHeader td {
  font-size: 19px !important;
  font-family: "SancoaleSoftened-Medium", sans-serif !important;
  padding: 5px;
}

.required {
  background-color: #00d66925;
}

.nameProgressActive {
  color: #780095;
  font-family: Noto Sans, sans-serif;
  font-size: 12px;
  text-align: center;
  min-width: 100px;
}

.nameProgress {
  color: #cdcdcd;
  font-family: Noto Sans, sans-serif;
  font-size: 12px;
  text-align: center;
  min-width: 100px;
}

.buttonSaveNext {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 1%;
  background-color: #ff0081;
  color: #fff;
  width: 150px;
  height: 29px;
  font-size: 15px;
  border: none;
  border-radius: 7px;
  margin-top: 2vh;
  font-family: "SancoaleSoftened-Regular", sans-serif;
  align-self: flex-end;
}

.buttonSaveNext:hover {
  background-color: #780095;
}

.disabled {
  color: #fff !important;
  background-color: #707070;
}

.disabled:hover {
  color: #fff !important;
  background-color: #707070;
}

.fileconverter {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fileConverteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.nameProcessProgressBar {
  width: 200px;
  text-align: left;
  line-height: 40px;
  color: #ff0081;
  font-family: "SancoaleSoftened-Medium", sans-serif;
  font-size: 33px;
  overflow-wrap: break-word;
}

.progressIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.iconsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100px;
  gap: 10px;
}

.progressButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-left: 4%;
}

.buttonIcon {
  width: 14px;
}

.buttonBackIcon {
  width: 16px;
}

.fileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.buttonUploadFile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 12px;
  background-color: #ff0081;
  color: #fff;
  width: 150px !important;
  height: 29px;
  font-size: 15px;
  border: none;
  border-radius: 7px !important;
  align-self: center;
  font-family: "SancoaleSoftened-Regular", sans-serif !important;
}

.calculations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}

.rowCalculations {
  display: flex;
  flex-direction: row;
  place-content: space-around;
  width: 100%;
  align-items: center;
}

.columnCalculations {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.gridCalculations {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.calculationItem {
  display: flex;
  flex-direction: column;
}

.titleFileUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  padding-left: 30px;
  font-family: "SancoaleSoftened-Medium", sans-serif;
  color: #575756;
  font-size: 23px;
  border-bottom: 1px solid #707070;
}

.uploadContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin-top: 20px;
  height: 200px;
}

.chipRegla {
  margin: 5px;
  width: 220px;
  color: #fff !important;
  font-family: Noto Sans, sans-serif !important;
  background-color: #0086ff !important;
  border-radius: 12px !important;
}

.chipRegla1 {
  background-color: #0086ff !important;
}

.chipRegla2 {
  background-color: #00d669 !important;
}

.titleCalculations {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 81px;
  width: 100%;
  border-bottom: 1px solid #707070;
  font-family: "SancoaleSoftened-Medium", sans-serif;
  color: #575756;
  font-size: 23px;
}
.calculationsContent {
  height: 200px;
  margin-top: 20px;
}

.borderCalculation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  padding: 20px 14px;
  max-height: 500px;
  width: 470px;
  border-radius: 21px;
  margin: 8px 0 7px 0px;
}

.subtitleCalculations {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-family: Noto Sans, sans-serif;
  align-self: center;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #707070;
  width: 450px;
}

.fieldTitle {
  display: flex;
  justify-content: space-around;
  margin: 14px 0;
  color: #707070;
}

.innerBoxCalculations {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.listItemCalculation {
  border-bottom: 0.3px solid #e0e0e0;
  border-top: 0.3px solid #e0e0e0;
  margin-top: 7px;
}
