.modalProcessing {
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleProcessing{
  color:#ff0081 ;
  font-weight: bold;
}

.message{
  color: #616161;
}

.paperProcessing {
  background-color: #fff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 14px;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 2%;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px
}

.progressContainer{
  width: 90%;
}


