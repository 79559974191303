.boxLink {
  background-color: #ffffff;
  width: 90%;
  overflow-y: hidden;
  margin-bottom: 50px;
}

.containerLink {
  width: 100%;
  text-align: -webkit-center;
}

.containerTableLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 4.7%;
  margin-top: 20px;
}

.addCustomColum {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 1% !important;
  background-color: #780095 !important;
  color: #fff !important;
  width: 250px !important;
  height: 35px;
  font-size: 15px;
  border: none;
  border-radius: 7px !important;
  margin-top: 2vh !important;
  font-family: "SancoaleSoftened-Regular", sans-serif !important;
  align-self: flex-start;
}

.addCustomColum:hover {
  background-color: #ff0081 !important;
}

.first_rowLink {
  display: flex;
  flex-direction: row;
  place-content: space-around;
  align-items: center;
  color: #fff;
  width: 90%;
  margin-top: 29px;
  padding-right: 1px;
  background-color: #780095;
  height: 60px;
  margin-left: -2px;
  font-weight: 300 !important;
}

.titleTableLink {
  font-family: "SancoaleSoftened-Regular", sans-serif;
  font-size: 25px;
}

.subtitleTableLink {
  font-family: Noto Sans, sans-serif;
  color: #575756;
}

.indexLink {
  width: 5%;
  padding-left: 0;
}

.descriptionLink {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  padding-left: 5%;
}

.headersBoxes {
  width: 65%;
  display: flex;
  justify-content: flex-end;
}

.contentTableLink {
  overflow-y: scroll;
  height: 407px;
  width: 100%;
}

.contentTableLink::-webkit-scrollbar {
  width: 3px;
  background-color: #d9dada;
}

.contentTableLink::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #575756;
  border-radius: 10px;
}

.rowsLinks:last-child {
  display: flex;
  flex-direction: row;
  place-content: center;
  align-items: center;
  border: 1px solid #780095;
  width: 90%;
  box-sizing: content-box;
}

.rowsLinks {
  display: flex;
  flex-direction: row;
  place-content: center;
  align-items: center;
  border: 1px solid #780095;
  border-bottom: none;
  width: 90%;
  box-sizing: content-box;
}

.rowsLinks:hover {
  background-color: #f7f7f7;
}
