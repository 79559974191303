.registeModelContainer {
  display: flex;
  gap: 20px;
  padding: 10px;
  justify-content: center;
}

.inputRegisterModel {
  width: 45% !important;
}

.inputNameModel {
  width: 45% !important;
  align-self: center;
}

.columsTable {
  width: 90.5%;
  align-self: center;
  margin-bottom: 20px;
}

.registerModelButton {
  align-self: center;
  margin-bottom: 10px;
  height: 54px;
  width: 150px;
  background-color: #fff !important;
  color: #a00095 !important;
  border-radius: 4px !important;
  border: 1px solid #a00095 !important;
  font-size: 16px;
  font-weight: normal !important;
  font-style: normal !important;
  font-family: Noto Sans, sans-serif !important;
}

.registerModelButton:hover {
  color: #fff !important;
  background: rgba(122, 33, 132, 255) !important;
  transform: translateY(+1px) !important;
}
