.section_sa {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
}
.cont_super_admin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.card_sa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
  height: 80%;
  background-color: #eeeeee;
  border-radius: 20px;
}
.middle_div_line {
  width: 2px;
  height: 80%;
  background-color: rgb(181, 181, 181);
}
.card_step {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
}
.header_step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 20%;
}
.header_step h3 {
  color: rgb(114, 114, 114);
  font-weight: 500;
  margin-left: 3%;
}
.body_step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 80%;
}
.body_step p {
  margin-bottom: 80px;
}
.circle_number_step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: rgb(146, 146, 146);
}
.circle_number_step p {
  color: white;
}
.selectSuperAdmin {
  height: 60px;
  border: 1px solid #a5a5a5;
  position: relative;
  background-color: white;
  width: 53%;
  border-radius: 4px;
}

/* new css */

.searchUserButton {
  background-color: #fff !important;
  color: #a00095 !important;
  border-radius: 4px !important;
  border: 1px solid #a00095 !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-family: Noto Sans, sans-serif !important;
  box-shadow: none;
}

.createClientButton {
  height: 54px;
  margin-top: -2px;
  width: 150px;
  background-color: #fff !important;
  color: #a00095 !important;
  border-radius: 4px !important;
  border: 1px solid #a00095 !important;
  font-size: 16px;
  font-weight: normal !important;
  font-style: normal !important;
  font-family: Noto Sans, sans-serif !important;
}

.createClientButton:hover {
  color: #fff !important;
  background: rgba(122, 33, 132, 255) !important;
  transform: translateY(+1px) !important;
}

.searchUserButton:hover {
  color: #fff !important;
  background: rgba(122, 33, 132, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transform: translateY(+1px) !important;
}

.paperSuperAdmin {
  padding: 1vw;
  margin-top: 5vh;
}

.paperSuperAdminMenu {
  padding: 0 1vw 1vw 1vw;
  margin-top: 5vh;
}

.stepperContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 2vh;
  width: 100%;
}

.stepperContainer2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 2vh;
}

.buttonChageRol {
  color: #fff !important;
  background-color: #a00095 !important;
}

.marginArroundLeft {
  margin: 5px 5px 5px 0px;
}
.marginArroundCenter {
  margin: 5px;
}
.marginArroundRight {
  margin: 5px 0px 5px 0px;
}

.adminPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  padding: 1vw 4.5vw;
}

.panelTitle {
  text-align: flex-start;
  font-size: 20px;
  color: #a00095;
  font-weight: 600;
  margin-left: 4.5vw;
}
.marginLeft {
  margin-left: 0;
}

.paneldescription {
  text-align: flex-start;

  color: #a5a5a5;

  margin-left: 1vw;
}

.adminMenu {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  border: 1px solid #a00095;
}

.adminMenuItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 15%;

  border: 1px solid #a00095;
}

.selectedMenu {
  background-color: #a00095;
  color: #eeeeee;
}

.adminMenuItem:hover {
  background-color: #a00095;
  color: #eeeeee;
}

.adminContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checkboxClients {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.superAdminInputsContainer {
  display: flex;
  gap: 10px;
  padding: 0 4.5vw;
}

.buttonDeleteUser {
  background-color: #ffffff;
  margin: 14px;
  color: #a00095;
  border-radius: 4px;
  border: 1px solid #a00095;
  font-weight: normal;
  font-style: normal;
  font-family: Noto Sans, sans-serif;
}

.buttonDeleteUser:hover {
  color: #ffffff;
  background-color: #a00095;
  box-shadow: 0px 5px 5px rgb(138, 20, 140);
  transform: translateY(+1px);
}

.buttonChangeRol {
  color: #ffffff;
  background-color: #a00095;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
}

.text {
  width: 300px;
  align-self: center;
}
