.common {
  background-color: #fff;
  display: flex;
  place-content: center;
  flex-direction: row;
}

.title {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}
.subtitle {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.main-box {
  width: 100%;
  text-align: -webkit-center;
}
.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

/*Talvez Dropzone no se utilice entonces borrar*/

.dropzone {
  font-size: 15px;
}

.MuiDropzoneArea-root {
  min-height: 100px !important;
}

.MuiDropzoneArea-text {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.MuiTypography-h5 {
  font-size: 1rem !important;
  font-family: Noto Sans, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 1.334 !important;
  letter-spacing: 0em !important;
}

.MuiDropzoneArea-icon {
  color: #a00095 !important;
  width: 45px !important;
  height: 45px !important;
}

/*Talvez Dropzone no se utilice entonces borrar*/

.colComponentsCalculations {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
}

.processed-list {
  overflow-y: scroll;
  overflow-x: none;
  height: 64vh;
  width: 80%;
}

/* width */
.processed-list::-webkit-scrollbar {
  width: 0.2em;
}

/* Handle */
.processed-list::-webkit-scrollbar-thumb {
  background: #a00095;
}
