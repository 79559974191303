.modalAddExcluding {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperAddExcluding {
  background-color: #fff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 14px;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  display: flex;
  height: 630px;
  flex-direction: column;
  align-items: center;
}

.inputAddExcluding {
  width: 300px;
  margin: 10px;
}

.selectModalAddHeader {
  width: 95% !important;
}

.subtitleModalContent {
  color: #575756;
  font-size: 14px;
  font-family: Noto Sans, sans-serif;
}

.addInputExcluding {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 1% !important;
  background-color: #780095 !important;
  color: #fff !important;
  width: 250px !important;
  height: 35px;
  font-size: 15px;
  border: none;
  border-radius: 7px !important;
  font-family: "SancoaleSoftened-Regular", sans-serif !important;
}

.replaceInputContainer {
  height: 280px;
  width: 700px;
  overflow-y: scroll;
  overflow-x: none;
}

.replaceInputContainer::-webkit-scrollbar {
  width: 3px;
  background-color: #d9dada;
}

.replaceInputContainer::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #575756;
  border-radius: 10px;
}

.subtitleCondition {
  color: #575756;
  font-size: 14px;
  font-family: Noto Sans, sans-serif;
  padding-left: 2%;
}

.buttondeleteExcluding {
  background-color: none;
  border: none;
}
