.modalAddFill {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperAddFill {
  background-color: #fff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 14px;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  display: flex;
  width: 600px;
  flex-direction: column;
  align-items: center;
}

.titleModalAddFill {
  color: #ff0081;
  font-family: "SancoaleSoftened-Medium", sans-serif;
  font-size: 30px;
  align-self: flex-start;
  margin-bottom: 8px;
}

.selectModalAddFill {
  width: 95% !important;
}

.formControlModalOPE {
  display: flex !important;
  align-items: center;
  width: 100% !important;
  margin-top: 20px !important;
  margin-bottom: 15px !important;
}

.formControlModalOP {
  display: flex !important;
  align-items: flex-start;
  width: 50% !important;
  margin-top: 20px !important;
  margin-bottom: 15px !important;
}
