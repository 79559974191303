.table-container{
    display: flex;
    justify-content: center;
    width: 100% !important;
    margin-top: 25px;
}

.table-container table{
    width: 70% !important;
    border-collapse: collapse;
}

.table-container table tr{
   border-bottom: 1px solid #000;
}

.tableGrid{
    text-align: center;
}


.arrowIcon:hover {
    color: #A00095
}

