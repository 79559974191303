.modalAddCalculation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paperModalAddCalculation {
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
  padding: 2%;
  width: 31%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formControlModalAddCalculation {
  display: flex !important;
  align-items: center;
  width: 99.5% !important;
  margin-bottom: 12px !important;
}

.selectModalAddCalculation {
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.buttonModalAddCalculation {
  background-color: #ff0081;
  color: #fff;
  width: 80px;
  height: 40px;
  font-size: 19px;
  border: none;
  border-radius: 7px;
  font-family: "SancoaleSoftened-Regular", sans-serif;
  align-self: flex-end;
}

.inputModalAddCalculation {
  box-sizing: border-box;
  height: 60px;
  border: 0.7px solid #575756;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 12px;
  padding: 2px 15px;
}
