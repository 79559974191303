.reportsMenuContainer{
    display: flex;
    width: 50%;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selectClientReports{
    width: 100% !important;
}

.inputDateReports{
    width: 100% !important;
    margin: 10px 0px !important;
}

.containerCheckboxReports{
    margin: 20px 0;
}