.contentTableProcess {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 426px;
  width: 100%;
}

.contentTableProcess::-webkit-scrollbar {
  width: 3px;
  background-color: #d9dada;
}

.contentTableProcess::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #575756;
  border-radius: 10px;
}

.subtitleCollectionIndex {
  width: 10%;
}

.columnProcessCollection {
  flex-basis: 55%;
  display: flex;
  justify-content: center;
}

.chipProcess{
  margin: 10px 0;
  color: #fff !important;
  width: 250px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-family: Noto Sans, sans-serif !important;
  border-radius: 12px !important;
}

.chipTipoCollection {
  background-color: #ff5c00 !important;
}

.chipReglasCollection {
  background-color: #c30091 !important;
}

.chipSpecialOp{
  background-color: #2d00a0 !important;
}

.chipNACollection {
  color: #c9c9c9 !important;
  border: 1px solid #c9c9c9 !important;
  background-color: transparent !important;
}

.chipExcludingCollection {
  background-color: #ff0082 !important;
}
