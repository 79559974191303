.login-img {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background-image: url("../../assets/images/Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.login-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 30vh;
  width: 20vw;
}

.header-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.header-box img {
  width: 700px;
  margin-bottom: 14.31px;
}

.mid-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  place-content: center;
}

.buttonLogin {
  cursor: pointer;
  background-color: #780095;
  width: 134.85px;
  height: 44.86px;
  color: #fff;
  border-radius: 25px;
  border: none;
  font-family: "SancoaleSoftened-Regular", sans-serif !important;
  font-size: 29.79px;
  font-weight: normal;
  margin-top: 28.91px;
}

.buttonLogin:hover {
  color: #fff;
  box-shadow: 0px 3px 3px #fff;
  transform: translateY(+1px);
}
