.downloadIcon:hover {
  color: #a00095;
}

.noFileUploaded {
  margin-top: 10%;
}

.buttonProcessAnalytic {
  background-color: #ff0081 !important;
  margin-left: 0px !important;
  color: #fff !important;
  width: 100px !important;
  height: 30px;
  font-size: 17px;
  border: none;
  border-radius: 7px !important;
  font-family: "SancoaleSoftened-Regular", sans-serif !important;
}

.buttonProcessAnalytic:hover {
  background-color: #a00095 !important;
}
.disabledButton {
  background-color: #c8c8c8 !important;
  color: #888 !important;
}
.disabledButton:hover {
  background-color: #c8c8c8 !important;
  color: #888 !important;
}

.center {
  align-self: center;
  margin-top: 10px;
}
